@import 'style.sass'
@import 'vars.sass'
@include button("orange")
@include button("violet")
@include button("black")
.split
  min-height: 60vh

  &#kids
    padding-top: 0
    @media screen and (max-width: 1270px)
      padding-top: 6vh
    @media screen and (max-width: 850px)
      padding-top: 2em
.gradient-bg
  margin-top: 2em

#educators, #adults, #kids
  .image img
    width: 100%

#accessible
  padding: 0
  grid-template-columns: 45% 55%
  grid-template-rows: auto
  align-items: start
  min-height: auto
  .text
    max-width: 100%
    margin-left: 10%
    h1
      font-size: 3.25em
    p
      margin-top: 1em
      margin-bottom: 2em
    button
      padding: 1rem 2.5rem
  .image
    width: 100%
    background-image: url('../images/home-topgradient-compressed-lowres.png')
    background-size: cover
    padding-top: 2.5em
    display: grid
    align-items: end
    img
      margin-left: 7%
      width: 88%
  @media screen and (max-width: 850px)
    grid-template-columns: 100%
    grid-template-rows: auto auto
    background-image: $gradient
    padding-bottom: 0
    h1
      margin-top: 1.3em
      font-size: 1.8em !important
    button
      background-color: $violet
      border-color: $violet
    .text
      margin: 0 6%
    .image
      margin-top: 1em
      padding-top: 0
      img
        margin: 0 auto
    .image,.text
      background-image: none
      align-self: end

#kids,#adults,#educators
  a
    button
      margin-top: 1em
  ul
    padding-inline-start: 20px
  .icons
    img
      margin-left: 0
      margin-right: .2em
  @media screen and (max-width: 850px)
    padding-top: 2em
    button
      margin-left: 0
    ul
      padding-left: 1em

#educators
  @media screen and (max-width: 850px)
    button
      margin-bottom: 2em
